import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import * as bottomNavigationStyling from './bottomNavigation.module.scss'

const bottomNavigation = ({ title, previous, next }) => {
    return (
        <nav className={bottomNavigationStyling.bottomNavigationWrapper}>
            <div>
                {previous && (
                    <Link to={previous.slug}>
                        <p className={bottomNavigationStyling.headline}>
                            {'Föregående ' + title}
                        </p>
                        <span className={bottomNavigationStyling.title}>
                            {previous.title}
                        </span>
                    </Link>
                )}
            </div>
            <div className={bottomNavigationStyling.nextWrapper}>
                {next && (
                    <Link to={next.slug}>
                        <p className={bottomNavigationStyling.headline}>
                            {'Nästa ' + title}
                        </p>
                        <span className={bottomNavigationStyling.title}>
                            {next.title}
                        </span>
                    </Link>
                )}
            </div>
        </nav>
    )
}

bottomNavigation.propTypes = {
    title: PropTypes.string.isRequired,
    next: PropTypes.object,
    previous: PropTypes.object,
}

export default bottomNavigation
