import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql, Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import Comments from '../components/common/comments'
import Hero from '../components/hero'
import RichTextRenderer from '../components/richTextRenderer'
import Share from '../components/share'
import BottomNavigation from '../components/layout/bottomNavigation'
import Quiz from '../components/quiz'
import * as articlePageStyling from './article.module.scss'

export default function ClassPage({ data, pageContext, location }) {
    const { contentfulClass } = data
    const { previous, next, course } = pageContext

    const pageURL = `${data.site.siteMetadata.siteUrl}${location.pathname}`

    const quizQuestions = contentfulClass.quiz
        ? JSON.parse(
              contentfulClass.quiz.childContentfulQuizQuestionsJsonNode.internal
                  .content
          ).quiz
        : null

    return (
        <>
            <GatsbySeo
                title={contentfulClass.title}
                description={contentfulClass.subtitle}
            />
            <Hero
                heading={contentfulClass.title}
                body={contentfulClass.subtitle}
            />
            <article className={articlePageStyling.article}>
                <p className={articlePageStyling.caption}>
                    Publicerat {contentfulClass.createdAt} i kursen{' '}
                    {course.slug !== null && (
                        <Link to={course.slug}>{course.title.trim()}.</Link>
                    )}
                </p>
                <RichTextRenderer
                    richText={contentfulClass.content}
                    showTableOfContents={true}
                    tableOfContentsHeadline={'Kursinnehåll'}
                />
                {quizQuestions && (
                    <Quiz
                        fullDesign={false}
                        questions={quizQuestions}
                        difficulty={contentfulClass.quiz.difficulty}
                        title={contentfulClass.quiz.title}
                        description={contentfulClass.quiz.description}
                    />
                )}
                <div className={articlePageStyling.credits}>
                    <GatsbyImage
                        image={contentfulClass.author.image.gatsbyImageData}
                        alt={contentfulClass.author.name}
                        style={{ minWidth: '64px' }}
                    />
                    <div className={articlePageStyling.creditsText}>
                        <p className={articlePageStyling.caption}>
                            Publicerat {contentfulClass.createdAt} i kursen{' '}
                            {course.slug !== null && (
                                <Link to={course.slug}>
                                    {course.title.trim()}.
                                </Link>
                            )}
                        </p>
                        <p>Av {contentfulClass.author.name}</p>
                        <Share url={pageURL} />
                    </div>
                </div>
            </article>
            <BottomNavigation title={'del'} previous={previous} next={next} />
            {!quizQuestions && (
                <Comments
                    contentfulPageId={contentfulClass.contentful_id}
                    headline={'Frågor? Funderingar? Kommentera gärna!'}
                />
            )}
        </>
    )
}

export const pageQuery = graphql`
    query ($title: String!) {
        contentfulClass(title: { eq: $title }) {
            id
            contentful_id
            title
            subtitle
            createdAt(formatString: "D MMMM YYYY", locale: "sv")
            updatedAt(formatString: "D MMMM YYYY", locale: "sv")
            quiz {
                title
                difficulty
                description
                childContentfulQuizQuestionsJsonNode {
                    internal {
                        content
                    }
                }
            }
            author {
                name
                image {
                    gatsbyImageData(
                        width: 64
                        height: 64
                        quality: 100
                        layout: FIXED
                        placeholder: BLURRED
                    )
                }
            }
            content {
                raw
                references {
                    ... on ContentfulAsset {
                        __typename
                        contentful_id
                        title
                        gatsbyImageData(
                            width: 1280
                            layout: CONSTRAINED
                            placeholder: BLURRED
                        )
                    }
                    ... on ContentfulFactBox {
                        __typename
                        contentful_id
                    }
                    ... on ContentfulWineCarousel {
                        __typename
                        contentful_id
                    }
                    ... on ContentfulWine {
                        ...WineTeaserFields
                    }
                    ... on ContentfulClass {
                        __typename
                        contentful_id
                        slug
                    }
                }
            }
        }
        site {
            siteMetadata {
                siteUrl
            }
        }
    }
`
